import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TailSpin } from 'react-loader-spinner';
import { Category, PaginationInfo, Product } from '../../models';
import ProductCard from './ProductCard';

interface HomeCatalogProps {
  fetchData: () => Promise<void>;
  currentProductPagination: {
    items: Array<Product>;
    count: number;
    pageInfo: PaginationInfo;
  };
  products: Product[];
  noResults: boolean;
  selectedCategory: Category;
  selectedSubCategory: string[];
  usingAlgolia: boolean;
}

function HomeCatalog({
  currentProductPagination,
  fetchData,
  products,
  noResults,
  selectedCategory,
  selectedSubCategory,
  usingAlgolia,
}: HomeCatalogProps) {
  const [filterProducts, setFilterProducts] =
    React.useState<Product[]>(products);

  React.useEffect(() => {
    const filter = [...products];
    if (selectedCategory !== null) {
      if (selectedSubCategory?.length !== 0) {
        filter.filter((product) =>
          selectedSubCategory?.includes(product?.subcategory)
        );
        setFilterProducts(
          filter.filter((product) =>
            selectedSubCategory?.includes(product?.subcategory)
          )
        );
      } else if (usingAlgolia) {
        setFilterProducts(
          filter.filter(
            (product) =>
              // Los productos del algolia vienen con category = categoryId
              product?.category === selectedCategory?._id
          )
        );
      } else {
        setFilterProducts(
          filter.filter(
            (product) =>
              // Los de query vienen con category = Category
              product?.category?._id === selectedCategory?._id
          )
        );
      }
    } else {
      setFilterProducts(products);
    }
  }, [products, selectedCategory, selectedSubCategory, usingAlgolia]);

  return (
    <div className="w-full flex flex-col mt-5">
      {noResults ? (
        <p className="w-full flex justify-center items-center mt-5 mb-10">
          No se han encontrado resultados para su busqueda
        </p>
      ) : null}
      <div className="w-full h-auto">
        <InfiniteScroll
          dataLength={products?.length}
          next={fetchData}
          hasMore={currentProductPagination?.pageInfo?.hasNextPage}
          loader={
            <div className="mt-5 w-full flex flex-row justify-center">
              <TailSpin
                height={80}
                width={80}
                color="#353577"
                ariaLabel="loading"
              />
            </div>
          }
          endMessage={
            <h4 className="mt-5 w-full text-center text-font-black">
              Ya viste todos los productos
            </h4>
          }
          height={700}
        >
          <div
            className={`flex flex-row flex-wrap items-center gap-5 sm:gap-8 md:gap-10 lg:gap-12 py-5 ${
              usingAlgolia || selectedCategory !== null
                ? 'justify-start'
                : 'justify-center'
            }`}
          >
            {filterProducts
              .filter((p) => p.active)
              .map((product) => (
                <div key={usingAlgolia ? product.objectID : product?._id}>
                  <ProductCard
                    product={product}
                    productId={usingAlgolia ? product?.objectID : product?._id}
                  />
                </div>
              ))}
          </div>
        </InfiniteScroll>
      </div>
    </div>
  );
}

export default HomeCatalog;
