/* eslint-disable no-nested-ternary */
import React from 'react';
import { Hit, SearchResponse } from '@algolia/client-search';
import { useMutation, useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { validateString } from 'avilatek-utils';
import { TailSpin } from 'react-loader-spinner';
import { useNotify, useUser } from '../hooks';
import useAlgolia from '../hooks/useAlgolia';
import { SIGN_OUT } from '../graphql/mutations';
import {
  FILTER_PRODUCTS_CATEGORY_SUBCATEGORY_PAGINATION,
  GET_CATEGORIES,
  GET_PRODUCTS,
  GET_PRODUCTS_PAGINATION,
} from '../graphql/queries';
import { Category, PaginationInfo, Product } from '../models';
import Layout from '../components/layouts/Layout';
import Carousel from '../components/home/Carousel';
import HomeCategories from '../components/home/HomeCategories';
import HomeCatalog from '../components/home/HomeCatalog';
import SearchHeader from '../components/home/SearchHeader';
import CategoryHeader from '../components/home/CategoryHeader';

function HomePage() {
  const [products, setProducts] = React.useState<Product[]>([]);
  const [allProducts, setAllProducts] = React.useState([]);
  const searchClient = useAlgolia();
  const searchIndex = searchClient.initIndex('products');
  const [searchResult, setSearchResult] = React.useState<Hit<Product>[]>(null);
  const [search, setSearch] = React.useState('');
  const [selectedCategory, setSelectedCategory] =
    React.useState<Category>(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState([]);
  const [noResults, setNoResults] = React.useState(false);
  const [usingAlgolia, setUsingAlgolia] = React.useState(false);

  const { data: categoriesData, loading: categoriesLoading } = useQuery<{
    categories: Category[];
  }>(GET_CATEGORIES, {
    variables: {
      sort: 'NAME_ASC',
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: dataFilter,
    loading: loadingFilter,
    error: errorFilter,
    fetchMore: fetchMoreFilter,
  } = useQuery<{
    filterProductsPagination: {
      items: Array<Product>;
      count: number;
      pageInfo: PaginationInfo;
    };
  }>(FILTER_PRODUCTS_CATEGORY_SUBCATEGORY_PAGINATION, {
    variables: {
      data: {
        category: selectedCategory?._id,
        subcategories: selectedSubCategory,
        page: 1,
        perPage: 20,
      },
    },
    fetchPolicy: 'network-only',
  });

  const { data, loading, error, fetchMore } = useQuery<{
    productPagination: {
      items: Array<Product>;
      count: number;
      pageInfo: PaginationInfo;
    };
  }>(GET_PRODUCTS_PAGINATION, {
    variables: {
      page: 1,
      perPage: 20,
    },
    fetchPolicy: 'network-only',
  });

  const [currentProductPagination, setCurrentProductPagination] =
    React.useState<{
      items: Array<Product>;
      count: number;
      pageInfo: PaginationInfo;
    }>();
  React.useEffect(() => {
    if (data?.productPagination && !loading && !error) {
      setProducts(data?.productPagination?.items);
      setAllProducts(data?.productPagination?.items);
      setCurrentProductPagination(data?.productPagination);
    }
  }, [data, error, loading]);

  React.useEffect(() => {
    if (selectedCategory !== null) {
      if (
        dataFilter?.filterProductsPagination &&
        !loadingFilter &&
        !errorFilter
      ) {
        setProducts(dataFilter?.filterProductsPagination?.items ?? []);
        setAllProducts(dataFilter?.filterProductsPagination?.items ?? []);
        setCurrentProductPagination(dataFilter?.filterProductsPagination);
      }
    } else {
      setProducts(data?.productPagination?.items ?? []);
      setAllProducts(data?.productPagination?.items ?? []);
      setCurrentProductPagination(data?.productPagination);
    }
  }, [
    selectedCategory,
    dataFilter?.filterProductsPagination,
    errorFilter,
    loadingFilter,
    data?.productPagination,
  ]);

  React.useEffect(() => {
    if (validateString(selectedCategory)) {
      const filter = allProducts?.filter(
        (hit) => hit?.category?._id === selectedCategory
      );
      if (selectedSubCategory?.length > 0) {
        const moreFilter = filter?.filter((product) =>
          selectedSubCategory?.includes(product?.subcategory)
        );
        setProducts(
          moreFilter.filter(
            (product) => product?.active && product?.category !== null
          )
        );
      } else {
        setProducts(
          filter.filter(
            (product) => product?.active && product?.category !== null
          )
        );
      }
    } else {
      setProducts(allProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, selectedSubCategory]);

  const filterResults = (results: SearchResponse<Product>) => {
    if (selectedCategory !== null) {
      if (selectedSubCategory?.length > 0) {
        const catFilter = results?.hits?.filter(
          (hit) => hit?.category === selectedCategory?._id
        );
        return catFilter?.filter((product) =>
          selectedSubCategory?.includes(product?.subcategory)
        );
      }
      return results?.hits?.filter(
        (hit) => hit?.category === selectedCategory?._id
      );
    }
    return results?.hits;
  };

  const handleSearchText = async (searchText: string) => {
    try {
      if (searchText === '') {
        setSearchResult(null);
        setNoResults(false);
        return;
      }
      const result = await searchIndex.search<Product>(searchText);
      if (result?.hits?.length === 0) {
        setNoResults(true);
        setSearchResult(null);
        return;
      }

      const resultsFiltered = filterResults(result);
      setNoResults(false);
      setSearchResult(resultsFiltered?.filter((hit) => hit?.active));
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    handleSearchText(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    if (
      (searchResult === null || searchResult?.length === 0) &&
      data?.productPagination &&
      !loading &&
      !error
    ) {
      setProducts(data?.productPagination?.items);
      setCurrentProductPagination(data?.productPagination);
      setUsingAlgolia(false);
    } else {
      setProducts(searchResult);
      setUsingAlgolia(true);
    }
  }, [searchResult, data, error, loading]);

  const fetchData = async () => {
    if (selectedCategory !== null) {
      const newData = await fetchMoreFilter({
        variables: {
          page: (currentProductPagination?.pageInfo?.currentPage ?? 0) + 1,
          perPage: 24,
        },
      });
      setProducts([
        ...products,
        ...(newData?.data?.filterProductsPagination?.items ?? []),
      ]);
      setAllProducts([
        ...products,
        ...(newData?.data?.filterProductsPagination?.items ?? []),
      ]);
      setCurrentProductPagination(newData?.data?.filterProductsPagination);
    } else {
      const newData = await fetchMore({
        variables: {
          page: (currentProductPagination?.pageInfo?.currentPage ?? 0) + 1,
          perPage: 20,
        },
      });
      setProducts([
        ...products,
        ...(newData?.data?.productPagination?.items ?? []),
      ]);
      setCurrentProductPagination(newData?.data?.productPagination);
    }
  };

  return (
    <Layout search={search} setSearch={setSearch}>
      <div className="min-h-screen w-full flex flex-col">
        {search.length > 0 || selectedCategory ? null : <Carousel />}
        {categoriesLoading || loading ? (
          <div className="mt-5 w-full flex flex-row justify-center">
            <TailSpin
              height={80}
              width={80}
              color="#353577"
              ariaLabel="loading"
            />
          </div>
        ) : (
          <div>
            {search.length === 0 && !selectedCategory ? (
              <HomeCategories
                categories={categoriesData?.categories ?? []}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
              />
            ) : search.length === 0 && selectedCategory ? (
              <CategoryHeader
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                selectedSubCategory={selectedSubCategory}
                setSelectedSubCategory={setSelectedSubCategory}
              />
            ) : null}

            {search.length > 0 ? <SearchHeader search={search} /> : null}
            <HomeCatalog
              fetchData={fetchData}
              currentProductPagination={currentProductPagination}
              selectedCategory={selectedCategory}
              selectedSubCategory={selectedSubCategory}
              noResults={noResults}
              products={products ?? []}
              usingAlgolia={usingAlgolia}
            />
          </div>
        )}
      </div>
    </Layout>
  );
}

export default HomePage;
