/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { Subcategory } from '../../models';

interface SubCategoryItemProps {
  subcategory: Subcategory;
  selectedSubCategory: string[];
  setSelectedSubCategory: React.Dispatch<React.SetStateAction<string[]>>;
}

function SubCategoryItem({
  subcategory,
  selectedSubCategory,
  setSelectedSubCategory,
}: SubCategoryItemProps) {
  const addSubCategory = (_id) => {
    const mySubCategories = [...selectedSubCategory];
    mySubCategories.push(_id);
    setSelectedSubCategory(mySubCategories);
  };

  const removeSubCategory = (_id) => {
    const mySubCategories = selectedSubCategory.filter((i) => i !== _id);
    setSelectedSubCategory(mySubCategories);
  };

  return (
    <div
      className={`w-20 h-20 lg:w-24 lg:h-24 xl:w-28 xl:h-28 shadow-md hover:shadow-lg shadow-neutral-300/50 rounded-lg font-avenir bg-background-100 flex flex-col justify-center items-center ${
        selectedSubCategory?.includes(subcategory?._id)
          ? 'text-primary-300'
          : 'text-neutral-300'
      }`}
    >
      <button
        type="button"
        className="w-full h-full flex flex-col justify-center items-center py-1"
        onClick={(e) => {
          e.preventDefault();
          if (selectedSubCategory?.includes(subcategory?._id)) {
            removeSubCategory(subcategory._id);
          } else {
            addSubCategory(subcategory?._id);
          }
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }}
      >
        <div className="w-full h-2/3 flex justify-center items-center">
          <img
            className={`w-8 lg:w-10 xl:w-12 object-center object-cover ${
              selectedSubCategory?.includes(subcategory?._id)
                ? 'selected-category'
                : 'category'
            }`}
            src={subcategory?.icon ?? '/img/mar_logo.png'}
            alt={subcategory?.name}
          />
        </div>
        <p className="w-full text-center text-sm lg:text-base line-clamp-2 px-1">
          {subcategory?.name}
        </p>
      </button>
    </div>
  );
}

export default SubCategoryItem;
